import React, { useEffect, useState } from "react";
import { Button, Form, Image, Input, Modal, Typography, message } from "antd";
import ImageUploader from "../../components/ImageUploader";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import AddressPopup from "../subscription_agreement/Address";
const { Text } = Typography;

function Profile() {
  const { getUserByID, getUserById } = useB2BContext();
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [editContent, setEditContent] = useState<any>({
    id: "",
    username: "",
    full_name: "",
    company: "",
    email: "",
    image: "",
  });
  const [password, setPassword] = useState("");
  const [isEditable, setEditable] = useState<boolean>(true);
  const [visible, setIsVisible] = useState(false);
  const [isVisibleAddress, setIsVisibleAddress] = useState(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  // const [btnDisable, setBtnDisable] = useState<boolean>(false);

  useEffect(() => {
    setEditContent({
      id: getUserByID?._id,
      username: getUserByID?.username,
      full_name: getUserByID?.full_name,
      company: getUserByID?.company,
      email: getUserByID?.email,
      image: getUserByID?.cover_image,
    });
  }, [getUserByID]);

  const updateUser = () => {
    setisLoading(true);
    const formData: any = new FormData();
    formData.append("full_name", editContent?.full_name);
    formData.append("username", editContent?.username);
    formData.append("company", editContent?.company);
    formData.append("image", editContent?.image);
    authSVC
      .updateByUID(getUserByID?._id, formData)
      .then((res) => {
        setisLoading(false);
        message.success("Profile Updated Successfully");
        getUserById(getUserByID?._id);
        setEditable(true);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const updatePassword = () => {
    setisLoading(true);
    authSVC
      .changePassword({
        password: password,
      })
      .then((res) => {
        setisLoading(false);
        message.success("Password Updated Successfully");
        setIsVisible(false);
        setEditable(true);
        getUserById(getUserByID?._id);
        handleCancel();
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    setEditContent((prevData: any) => ({
      ...prevData,
      image: file,
    }));

    if (file) {
      reader.readAsDataURL(file);
    }
    setEditable(false);
  };

  const handleChangeInput = (e: any) => {
    setEditContent((prevData: any) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setEditable(false);
  };

  const handleCancel = () => setIsVisible(false);
  const handleCancelAddress = () => setIsVisibleAddress(false);

  const onFinish = () => {
    if (editContent.full_name === "") {
      message.info("Please Input Full Name");
      setEditable(true);
    } else if (editContent.username === "") {
      message.info("Please Input Username");
      setEditable(true);
    } else if (editContent.image === "") {
      message.info("Please upload a Image");
      setEditable(true);
    } else {
      setEditable(false);
      updateUser();
    }
  };
  // const nameValidation = (_: any, value: any) => {
  //   // Use a regular expression to validate that the input contains only letters
  //   const regex = /^[A-Za-z\s]+$/;

  //   if (!value || regex.test(value)) {
  //     return Promise.resolve();
  //   }

  //   return Promise.reject(
  //     new Error("Please enter a valid name with only letters.")
  //   );
  // };
  return (
    <div className="contentBox">
      <div className="info">
        <Text className="font14">Profile Details</Text>
        <div className="profile_wrapper">
          <ImageUploader
            borderRadius="50%"
            width="100px"
            height="100px"
            image={
              editContent?.image !== ""
                ? editContent?.image
                : "/img/new/profile.svg"
            }
            uploadedImage={uploadedImage}
            handleFileUpload={handleFileUpload}
          />

          <Form
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Input
              name="full_name"
              className="input"
              value={editContent?.full_name}
              onChange={handleChangeInput}
              type="text"
              placeholder="Enter Your Full Name"
              suffix={
                <Image
                  src={"/img/new/edit_input.svg"}
                  alt={""}
                  preview={false}
                />
              }
              style={{ marginBottom: "16px" }}
            />
            <Input
              name="email"
              className="input"
              value={editContent?.email}
              disabled
              type="email"
              placeholder="Enter Your Email"
              suffix={
                <Image
                  src={"/img/new/edit_input.svg"}
                  alt={""}
                  width={17}
                  height={17}
                  preview={false}
                />
              }
              style={{ marginBottom: "16px" }}
            />
            <Input
              name="company"
              className="input"
              value={editContent?.company}
              disabled
              type="text"
              placeholder="Enter Your Email"
              suffix={
                <Image
                  src={"/img/new/edit_input.svg"}
                  alt={""}
                  width={17}
                  height={17}
                  preview={false}
                />
              }
              style={{ marginBottom: "16px" }}
            />

            <div className="option_btn_set">
              <Button className="cn_btn" onClick={() => setIsVisible(true)}>
                Change Password
              </Button>
              <Button
                className="cn_btn"
                onClick={() => setIsVisibleAddress(true)}
                style={{ marginTop: "16px" }}
              >
                Your Office address
              </Button>
            </div>

            <Button
              loading={isLoading}
              disabled={isEditable}
              className="cn_btn"
              onClick={onFinish}
            >
              Update Profile
            </Button>
          </Form>
        </div>
      </div>

      <Modal
        open={visible}
        footer={false}
        closable
        onCancel={handleCancel}
        width={400}
      >
        <div className="contentBox">
          <div className="info">
            <Text className="font14">Change Password</Text>
            <div className="profile_wrapper">
              <Input
                className="input"
                type="text"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter New Password"
                suffix={
                  <Image
                    src={"/img/new/edit_input.svg"}
                    alt={""}
                    width={17}
                    height={17}
                    preview={false}
                  />
                }
              />
              <Button
                loading={isLoading}
                className="cn_btn"
                onClick={() => {
                  if (password === "") {
                    message.info("Please Fill Password");
                  } else {
                    updatePassword();
                  }
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isVisibleAddress}
        footer={false}
        closable
        onCancel={handleCancelAddress}
        width={400}
      >
        <AddressPopup />
      </Modal>
    </div>
  );
}

export default Profile;
