import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Input,
  message,
  Select,
  Form,
  Image,
} from "antd";
import { authSVC, categorySVC } from "../../services";
import { Option } from "antd/es/mentions";
import { useB2BContext } from "../../context/ContextB2B";
import { useNavigate, useLocation } from "react-router-dom";

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
  department: string;
  gender: string;
  marital_Status: string;
}

function EditEmployees() {
  const [form] = Form.useForm();
  const { getUserByID, setAllPackageList, allPackageList } = useB2BContext();
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    company: "",
    department: "",
    gender: "",
    marital_Status: "",
  });
  const [loading, setloading] = useState<boolean>(false);
  const [disableAddEmp, setDisableAddEmp] = useState(false);
  const [allDepartments, setAllDepartments] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const UserId = queryParams.get("uid");
  const [packageId, setPackageId] = useState<any>();

  useEffect(() => {
    getDepartments(getUserByID);
  }, [getUserByID]);

  const getDepartments = (getUserByID: any) => {
    if (getUserByID?.company) {
      authSVC
        .getCompanyDepartments(getUserByID?.company)
        .then((res: any) => {
          setAllDepartments(res.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  // For Package Changes

  // useEffect(() => {
  //   AllPackage();
  // }, [getUserByID]);

  // const AllPackage = async () => {
  //   authSVC
  //     .getAllPackage()
  //     .then((res: any) => {
  //       setAllPackageList(res.data.package);
  //     })
  //     .catch((err: any) => {
  //       message.error(err.message);
  //     });
  // };

  // const handleChange = (value: string) => {
  //   setPackageId(value);
  // };

  // useEffect(() => {
  //   const packageItem = allPackageList?.find(
  //     (item: any) => item._id === packageId
  //   );
  //   if (packageItem) {
  //     setPackageName(packageItem.package);
  //   }
  // }, [packageId, allPackageList]);

  useEffect(() => {
    if (getUserByID?.email) {
      authSVC
        .getCompanyuser()
        .then((res: any) => {
          res.data?.data.map((data: any) => {
            console.log(data, "emp data");
            if (data._id === UserId) {
              form.setFields([
                { name: "full_name", value: data?.full_name },
                { name: "department", value: data?.department },
                { name: "email", value: data?.email },
                { name: "package_name", value: data?.package_name },
                { name: "gender", value: data?.gender },
                { name: "marital_Status", value: data?.marital_Status },
              ]);
            }
          });
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  }, [getUserByID, form]);

  const handleInputChange = (e: any) => {
    console.log(e);
  };

  const handleSelectedDepratment = (value: any) => {
    console.log(value);
  };

  useEffect(() => {
    if (allDepartments?.message?.length === 0) {
      setDisableAddEmp(true);
    } else {
      setDisableAddEmp(false);
    }
  }, [allDepartments]);

  const handleSelectedGender = (value: any) => {
    console.log(value);
  };
  const handleSelectedMarital = (value: any) => {
    console.log(value);
  };

  const onFinish = (values: any) => {
    console.log(values, "values");
    authSVC
      .updateEmployee(UserId, {
        full_name: values.full_name,
        email: values?.email,
        gender: values?.gender,
        marital_Status: values?.marital_Status,
        department: values?.department,
      })
      .then((res: any) => {
        setloading(false);
        message.success("User Successfully Updated");
        form.resetFields();
        navigate("/manage_exployees");
      })
      .catch((err: any) => {
        message.error(err.response?.data?.error);
        setloading(false);
      });
  };

  const nameValidation = (_: any, value: any) => {
    const regex = /^[A-Za-z\s]+$/;

    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Please enter a valid name with only letters.")
    );
  };

  return (
    <div className="add_form_wrapper">
      <h1>Edit Employee</h1>
      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name="full_name"
          rules={[
            {
              required: true,
              message: "Please Enter Employee Full Name!",
            },
            {
              validator: nameValidation,
            },
          ]}
          style={{ marginBottom: "16px" }}
        >
          <Input
            name="full_name"
            className="input"
            value={editContent?.full_name}
            onChange={handleInputChange}
            type="text"
            placeholder="Please Enter Employee Full Name"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid Email!",
            },
          ]}
        >
          <Input
            className="input"
            name="email"
            onChange={handleInputChange}
            value={editContent.email}
            disabled
            placeholder={"Please Enter Employee Email ID"}
          />
        </Form.Item>

        <Form.Item name={"gender"}>
          <Select
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a gender"
            optionFilterProp="children"
            onChange={handleSelectedGender}
            value={editContent.gender}
          >
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>

        <Form.Item name={"marital_Status"}>
          <Select
            style={{ width: "100%", borderRadius: "24px" }}
            placeholder="Select a Marital Status"
            optionFilterProp="children"
            onChange={handleSelectedMarital}
            value={editContent.marital_Status}
          >
            <Option value="Married">Married</Option>
            <Option value="UnMarried">UnMarried</Option>
          </Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: "16px" }} name={"department"}>
          <Select
            style={{ width: "100%", borderRadius: "8px" }}
            placeholder="Please Select The Department"
            optionFilterProp="children"
            onChange={handleSelectedDepratment}
          >
            {allDepartments?.data?.map((item: any, index: any) => {
              return (
                <Option value={item.name} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* {allPackageList && (
          <Form.Item
            name="package_name"
            rules={[{ required: true, message: "Please input Package Name!" }]}
            className="form_item_blue"
          >
            <Select
              onChange={handleChange}
              options={allPackageList.map((option: any) => ({
                value: option._id,
                label: option.package,
              }))}
              placeholder="Please Assign Membership Plan"
            />
          </Form.Item>
        )} */}

        <Button
          className="button_add_emp"
          htmlType="submit"
          loading={loading}
          disabled={disableAddEmp ? true : false}
          icon={<Image src="/img/new/add.svg" alt="" />}
        >
          Update
        </Button>
      </Form>
    </div>
  );
}

export default EditEmployees;
