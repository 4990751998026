import React, { useEffect, useState } from "react";
import staticstyles from "../../styles/dashboard.module.scss";
import moment from "moment";
import { authSVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import { Checkbox, Modal, message } from "antd";
import type { CheckboxProps } from "antd";
import PaymentPopup from "./Payment";
import PaymentHDFC from "./PaymentHDFC";
import AgreementData from "./AgreementData";
import PaymentStatusPopup from "./PaymentStatusPopup";
import PaymentStatusFail from "./PaymentStatusFail";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

function SoulsaraAgreement() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { agreementId, getUserByID, setAgreementId, setUserByID } =
    useB2BContext();
  const [paymentDone, setPaymentDone] = useState(false);
  const [agreementData, setAgreementData] = useState<any>();
  const [agreeed, setAgreeed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCompany, setIsModalOpenCompany] = useState(false);
  const [isPaymentStatus, setIsPaymentStatus] = useState(false);
  const [isPaymentStatusFail, setIsPaymentStatusFail] = useState(false);

  const [paymentOption, setPaymentOption] = useState("hr");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const handleCancelPaymentStatusFail = () => {
    setIsPaymentStatusFail(false);
  };

  const handleCancelPaymentStatus = () => {
    setIsPaymentStatus(false);
    window.location.reload();
  };

  const handleOkCompany = () => {
    setIsModalOpenCompany(false);
  };

  const handlePaymentStatus = () => {
    setIsPaymentStatus(false);
  };

  const handleCancelCompany = () => {
    setIsModalOpenCompany(false);
  };

  const handlePaymentStatusFail = () => {
    setIsPaymentStatusFail(false);
  };

  const RetryPayment = () => {
    setIsModalOpen(true);
    setIsPaymentStatusFail(false);
  };

  useEffect(() => {
    if (getUserByID) {
      if (getUserByID?.company_id?.package.length === 0) {
        setIsModalOpenCompany(false);
      } else {
        authSVC
          .getAgreementsPostData(getUserByID.email)
          .then((res) => {
            if (res.data.success === false) {
              setIsModalOpenCompany(true);
            } else {
              setIsModalOpenCompany(false);
              setAgreementData(res?.data?.data);
            }
            console.log(res.data.success, "getAgreementsPostData");
          })
          .catch();
      }
    }
  }, [getUserByID]);

  useEffect(() => {
    if (getUserByID?.company_id?.package.length === 0) {
      setIsModalOpenCompany(false);
    }
  }, [getUserByID]);

  const onChange: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked == true) {
      setAgreeed(true);
    } else {
      setAgreeed(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hdfcParams = queryParams.get("status");
    console.log(hdfcParams, "hdfcParams");
    const order_id = localStorage.getItem("ss_order_id");
    if (getUserByID) {
      if (hdfcParams === "success") {
        authSVC
          .checkPaymentStatus({ order_id: order_id })
          .then((res) => {
            console.log(res.data, "initateHRMSPayment");

            if (res.data.status === "CHARGED") {
              // message.success("Payment Done");
              setIsPaymentStatus(true);
              let PaymentDetails: any = {};
              PaymentDetails = {
                user_id: getUserByID?._id,
                subscription:
                  getUserByID?.company_id?.package[0]?.package_id?.package,
                duration: "12 Months",
                date_of_purchase: new Date(),
                purchase_price: Number(
                  getUserByID?.company_id?.package[0]?.price
                ),
                status: "true",
                membership_type: "Company",
                Payment_method: "hdfc",
                order_id: order_id,
              };
              if (PaymentDetails) {
                console.log(PaymentDetails, "PaymentDetails");
                authSVC.Payment(PaymentDetails).then((res) => {
                  // message.success("Payment Plan Updated.");
                  if (location.search) {
                    navigate(location.pathname, { replace: true });
                  }
                  setPaymentDone(true);
                  localStorage.removeItem("pid");
                });
              }
            } else {
              message.success("Payment Not Done");
              // setIsPaymentStatusFail(true);
            }
          })
          .catch();
      } else if (hdfcParams === "fail") {
        setIsPaymentStatusFail(true);
      } else {
      }
    }
  }, [location, getUserByID, navigate]);

  // const HdfcHRTransaction = () => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const hdfcParams = queryParams.get("orderId");
  //   if (getUserByID) {
  //     authSVC
  //       .closeHdfcHRTransaction({
  //         company_id: getUserByID?.company_id?._id,
  //         order_id: hdfcParams,
  //       })
  //       .then((res) => {
  //         console.log(res.data, "initateHRMSPayment");
  //       })
  //       .catch();
  //   }
  // };

  useEffect(() => {
    console.log(getUserByID, "getUserByID");
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get("success");
    if (success) {
      if (getUserByID && getUserByID.is_payment === false) {
        let PaymentDetails: any = {};
        PaymentDetails = {
          user_id: getUserByID?._id,
          subscription:
            getUserByID?.company_id?.package[0]?.package_id?.package,
          duration: "12 Months",
          date_of_purchase: new Date(),
          purchase_price: Number(getUserByID?.company_id?.package[0]?.price),
          status: "true",
          membership_type: "Company",
        };
        if (PaymentDetails) {
          console.log(PaymentDetails, "PaymentDetails");
          authSVC.Payment(PaymentDetails).then((res) => {
            message.success("Payment Plan Updated.");
            if (location.search) {
              navigate(location.pathname, { replace: true });
            }
            setPaymentDone(true);
            localStorage.removeItem("pid");
          });
        }
      }
    }
  }, [getUserByID, location, navigate]);

  useEffect(() => {
    if (paymentDone) {
      authSVC
        .AgreementsDone(getUserByID?.email, { isSigned: true })
        .then((res) => {
          if (res.data.success) {
            authSVC
              .getByUID(getUserByID._id)
              .then((res) => {
                setUserByID(res.data.data);
              })
              .catch(() => {
                console.log("failed getting main user");
              });
          }
        })
        .catch((err) => {});
    }
  }, [paymentDone]);

  return (
    <div className={staticstyles.static_page_wrapper}>
      {getUserByID ? (
        <>
          {getUserByID?.company_id?.package.length === 0 ? (
            <p className="er_msg">
              Your package is not active yet. We will send you a confirmation
              email once it is ready. Need help? We are here for you!
            </p>
          ) : (
            <>
              {" "}
              <div className="logo_header">
                {/* <Link href="/">
          <Image
            src="/img/logo_new_v2.png"
            width={270}
            height={114}
            alt="SOULSARA, Your Wellness Community Transforming the Workplace "
          />
        </Link> */}
              </div>
              <div className={staticstyles.static_content_wrapper}>
                <div className={staticstyles.title}>
                  <h1>CORPORATE SUBSCRIPTION AGREEMENT</h1>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    This Corporate Subscription Agreement (hereinafter
                    “Agreement”) is signed on the{" "}
                    <strong>
                      {moment().format("Do")} day of {moment().format("MMM")}{" "}
                      {moment().format("yyyy")}
                    </strong>{" "}
                    (Effective Date) at{" "}
                    <strong>{agreementData?.location}</strong>
                  </p>
                  <p>By and Between</p>
                  <p>
                    <strong>Soul Studio Pvt. Ltd.</strong>, a company
                    incorporated and existing under the laws of India, having
                    its registered office at{" "}
                    <strong>
                      Y166, Regency Park-II, DLF Phase-IV, Gurugram Gurgaon,
                      Haryana, India, 122002
                    </strong>{" "}
                    hereinafter referred to as “Soul Studio”, (which expression
                    shall, unless it be repugnant to the subject or context
                    thereof, include its successors and permitted assigns)
                    represented by its Director Mr. Abhay Wadhwa, of the First
                    Part;
                  </p>
                  <p>And</p>
                  <p>
                    <strong>{agreementData?.clientCompanyName}</strong>, a
                    company incorporated and existing under the laws of{" "}
                    <strong>{agreementData?.country}</strong>, having their
                    office at <strong>{agreementData?.companyAddress}</strong> ,
                    hereinafter referred to as “Company” (which expression
                    shall, unless it be repugnant to the subject or context
                    thereof, include its successors and assigns) represented by
                    its Director <strong>{agreementData?.companyOwner}</strong>.
                  </p>
                </div>
                <div
                  className={staticstyles.title}
                  style={{ marginTop: "32px" }}
                >
                  <h1>WHEREAS:</h1>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>A.</strong> Soul Studio’s <strong>SOULSARA</strong>{" "}
                    is a platform that provides personalized and holistic
                    wellness solutions for businesses by using propriety
                    scientific tools and patented technologies to assess each
                    employee’s mental and emotional health and create a
                    customized wellness plan that includes online resources,
                    propriety modules, Wellness Psychometric Profile, trackers,
                    products and services, fitness challenges, and more thereby
                    helping employees improve their well-being, productivity,
                    and happiness at work and such plans and solutions are
                    provided through well-structured membership plans, which for
                    now are termed as Gold, Platinum and Diamond, based on which
                    the employees of the Company (employees) are offered certain
                    benefits including but not limited to Wellness Counseling
                    session via phone call with experiences counsellor or
                    therapist <strong>(therapist)</strong>.
                  </p>
                  <p>
                    <strong>B.</strong> Company is{" "}
                    <strong>{agreementData?.aboutTheCompanyName}</strong> is
                    desirous of subscribing to SOULSARA for the benefit of its
                    employees.
                  </p>
                  <p>
                    <strong>C.</strong> This Agreement elaborates the terms and
                    conditions of the above business relationship between Soul
                    Studio and the Company.
                  </p>
                  <p>
                    Soul Studio and the Company are hereinafter individually
                    referred to as a “Party” and collectively as “Parties”.
                  </p>
                  <p>
                    <strong>
                      NOW, THEREFORE, IN CONSIDERATION OF THE PREMISES SET FORTH
                      ABOVE, THE MUTUAL PROMISES AND COVENANTS SET FORTH HEREIN
                      AND OTHER GOOD AND VALUABLE CONSIDERATION, THE RECEIPT AND
                      ADEQUACY OF WHICH ARE HEREBY ACKNOWLEDGED, THE PARTIES
                      AGREE AS FOLLOWS:
                    </strong>
                  </p>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>1. Services:</strong>{" "}
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>1.1.</strong> SOULSARA is a wellness platform that
                      enhances the company’s zeitgeist by providing proven
                      wellness tools and helps employees thrive and businesses
                      grow and its services include, but are not restricted to,
                      wellness programmes through which employees of
                      organisations avail various products and Services.
                      SOULSARA’s service offerings include but not limited to
                      the following:
                      <ul>
                        <li>
                          <strong>a.</strong>{" "}
                          <strong>Neuro linguistic programming</strong> to
                          address thought patterns and behaviours for mental
                          wellness.{" "}
                        </li>
                        <li>
                          <strong>b.</strong> <strong>Color therapy </strong>{" "}
                          utilizes specific colours to evoke emotional responses
                          and promote balance.
                        </li>
                        <li>
                          <strong>c.</strong> <strong>Panic assist</strong>{" "}
                          offering immediate support resources to manage and
                          overcome anxiety or panic.
                        </li>
                        <li>
                          <strong>d.</strong>{" "}
                          <strong>Alternating binaural sounds</strong> to
                          promote relaxation and reduce stress through auditory
                          stimulation.
                        </li>
                        <li>
                          <strong>e.</strong>{" "}
                          <strong>Quantifiable results</strong> providing
                          personalized insights and tracking progress in
                          self-awareness and personal wellness.
                        </li>
                        <li>
                          <strong>f.</strong>{" "}
                          <strong>Wellness Counseling and Wellness Chat</strong>{" "}
                          to deliver real-time solutions from experienced
                          therapists.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>1.2.</strong> The Services offered by SOULSARA are
                      in compliance with its Privacy Policy and Terms &
                      Conditions available on its website
                      https://www.soulsara.world/ and Soul Studio reserves the
                      right to add or remove products and Services from its
                      overall offerings without prior notification.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>2. Disclaimer</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>2.1.</strong> Soul Studio does not deal with
                      medical or psychological emergencies and SOULSARA is not
                      developed to offer support in crisis situations, including
                      when an employee is experiencing thoughts of self-harm or
                      suicide, or is showing symptoms of severe clinical
                      disorders such as schizophrenia and other psychotic
                      conditions.
                    </li>
                    <li>
                      <strong>2.2.</strong> As per Soul Studio’s Privacy Policy,
                      SOULSARA shall not disclose to the Company if any of its
                      employees is exhibiting or experiencing thoughts of
                      self-harm or suicide or is showing symptoms of severe
                      clinical disorders such as schizophrenia and other
                      psychotic conditions that may harm the employee or others
                      around the employee.{" "}
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>3. Applicability Of Terms</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>3.1.</strong> The terms and conditions and privacy
                      policy available on{" "}
                      <a href="https://www.soulsara.world/">
                        https://www.soulsara.world/
                      </a>{" "}
                      are applicable to this Agreement between Soul Studio and
                      the Company.
                    </li>
                    <li>
                      <strong>3.2.</strong> These terms and conditions and
                      privacy policy applies to all users of SOULSARA, including
                      without limitation users who are browsers, vendors,
                      customers, merchants, and/or contributors of content and
                      by availing any of Soul Studio’s Services, the Company and
                      its employees irrevocably accepts all the obligations
                      stipulated in the terms and conditions and Privacy Policy,
                      other policies referenced herein; and agrees to abide by
                      them.
                    </li>
                    <li>
                      <strong>3.3.</strong> This Agreement supersedes any
                      previous oral or written terms and conditions that may
                      have been communicated to the Company.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>4. Termination of Agreement</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>4.1.</strong> At any point during the term of this
                      Agreement, the Company shall be entitled to terminate the
                      Agreement by giving 60 days notice to Soul Studio in
                      writing, provided all outstanding payments are settled, if
                      applicable. Upon termination of the Agreement, there may
                      still be some information saved on Soul Studio’s servers
                      regarding the Company or its employees, which will be
                      deleted by Soul Studio within{" "}
                      <strong>60 working days</strong>, in accordance with Soul
                      Studio’s Privacy Policy and subject to necessary
                      compliance with applicable laws.
                    </li>
                    <li>
                      <strong>4.2.</strong> During the terms of this Agreement,
                      Soul Studio can choose to terminate the Agreement without
                      citing any reason by giving 60 day notice to the Company
                      and any payment receivable by Soul Studio shall be paid by
                      the Company in full within 30 days from the termination of
                      the Agreement.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>5. Payment and Refunds</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>5.1.</strong> By using SOULSARA, the Company or
                      the employees, as the case may be, agrees to pay all
                      applicable fees and charges upfront as detailed in the
                      SOULSARA Membership Plans or agreed between the parties.
                    </li>
                    <li>
                      <strong>5.2.</strong> Further, the Company or the
                      employees, as the case may be, agree to be responsible for
                      any telephone charges and/ or internet service fees that
                      may be incurred in accessing SOULSARA.
                    </li>
                    <li>
                      <strong>5.3.</strong> For more information on payment
                      methods, charges and fees, the user shall refer to the
                      payment section on SOULSARAand Soul Studio reserves the
                      right to change any or all parts of payment policy without
                      notice or liability to the user or any third-party.
                    </li>
                    <li>
                      <strong>5.4</strong> All cancellations and refunds will be
                      handled as per the Cancellation and Refund Policy of Soul
                      Studio and Soul Studio reserves the right to change any or
                      all parts of the Cancellation & Refund Policy without
                      notice or liability to the user or any third party.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>6. Limitation of Liability</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>6.1.</strong> Soul Studio does not guarantee that
                      the employee will be accepted as a registered user at
                      SOULSARA, or as a client by the therapist on SOULSARA.
                      Soul Studio reserves the right to terminate the
                      registration of the employee with SOULSARA at any time
                      without citing a reason.{" "}
                    </li>
                    <li>
                      <strong>6.2.</strong> Soul Studio only connects the
                      employee and the therapists and the therapy is dependent
                      on the information provided by the employee to the
                      therapist. Soul Studio does not promise any outcomes based
                      on counseling and therapy. Soul Studio does not guarantee
                      the availability of the same therapist over any period of
                      time.
                    </li>
                    <li>
                      <strong>6.3.</strong> From the moment at which the
                      employee books an appointment, Soul Studio acts solely as
                      an intermediary between the employee and the therapist,
                      transmitting the details of the employee’s booking to the
                      therapist.
                    </li>
                    <li>
                      <strong>6.4.</strong> Counseling and therapy will take
                      place at a frequency agreed between the employee and the
                      therapist. Soul Studio cannot guarantee that sessions will
                      always take place with a particular frequency or on the
                      same day of the week but will make every effort to meet
                      the employees’ requirements.
                    </li>
                    <li>
                      <strong>6.5.</strong> During the term of this Agreement,
                      the therapist might determine that online counseling
                      services are not appropriate for some or all of the
                      employee’s treatment needs and accordingly may elect to
                      not provide online counseling services to the employee.
                      Similarly, the therapist shall be at the liberty to decide
                      that additional help is needed for the employee and shall
                      choose to bring in an additional therapist.
                    </li>
                    <li>
                      <strong>6.6.</strong> Soul Studio makes all efforts to
                      verify the credentials of every therapist registered with
                      SOULSARA, however, Soul Studio shall not be responsible
                      for any misrepresentation/fraudulent credentials or claims
                      by the therapist.
                    </li>
                    <li>
                      <strong>6.7.</strong> All therapists have a contractual
                      relationship with Soul Studio and are allowed to use
                      SOULSARA platform and technology to provide services to
                      the employees. SOULSARA does not assume any responsibility
                      for their actions, advice or any other information
                      provided through SOULSARA or otherwise. Soul Studio shall
                      not be held liable for any damage/ loss/ liability caused
                      to the employee by a therapist, either directly or
                      indirectly. The employee is responsible for all their
                      decisions and actions and agree that they are made
                      independent of any representations by Soul Studio or the
                      therapists. Soul Studio or the therapists cannot be held
                      responsible for such decisions and actions or any outcomes
                      as a result.
                    </li>
                    <li>
                      <strong>6.8.</strong> Soul Studio does not endorse or
                      influence control over any particular branch of medicine,
                      theory, opinion, viewpoint, or position on any topic. No
                      warranty, guarantee, or conditions of any kind are created
                      or offered on information advice, or suggestion, whether
                      expressed and/ or implied, in oral and/ or written via any
                      communication medium, obtained by the Company or its
                      employees from SOULSARA or through any Service and/ or
                      resource and/ or information that Soul Studio provides,
                      except for those expressly outlined in this Agreement.
                    </li>
                    <li>
                      <strong>6.9.</strong> The Services, including content, on
                      both the SOULSARA and third-party platforms, are provided
                      for general information only and should not be relied upon
                      or used as the sole basis for making decisions without
                      consulting primary, more accurate, more complete sources
                      of information. Soul Studio is not responsible if
                      information made available on SOULSARA and third-party
                      platforms is not accurate, complete, or current and any
                      reliance on the Services, including content, on SOULSARA
                      and third-party platforms is at the employee’s own risk.
                    </li>
                    <li>
                      <strong>6.10.</strong> Soul Studio reserves the right to
                      modify or terminate any portion of SOULSARA or the
                      services offered for any reason, without notice and
                      liability to the user or any third party.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>7. Disclaimer on Warranties</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>7.1.</strong> The Services and content, and all
                      materials, information, products, and Services included
                      therein, are provided on an “as is” and “as available”
                      basis without warranties of any kind.
                    </li>
                    <li>
                      <strong>7.2.</strong> Soul Studio and its licensors and
                      affiliates expressly disclaim all warranties of any kind,
                      express, implied, or statutory, relating to the Services
                      and content.
                    </li>
                    <li>
                      <strong>7.3.</strong> In addition, Soul Studio and its
                      licensors and affiliates disclaim any warranties regarding
                      the security, accuracy, reliability, timeliness, and
                      performance of the Services or that the Services will be
                      error-free or that any errors will be corrected.
                    </li>
                    <li>
                      <strong>7.4.</strong> Soul Studio makes no representations
                      concerning and does not guarantee, the accuracy of the
                      Services, including, but not limited to, any information
                      provided through the Services or their applicability to
                      the employee’s individual circumstances.
                    </li>
                    <li>
                      <strong>7.5.</strong> Third-party links on SOULSARA may
                      direct the employee to third-party websites that are not
                      affiliated with Soul Studio. Soul Studio is not
                      responsible for examining or evaluating the content or
                      accuracy and does not warrant and will not have any
                      liability or responsibility for any third-party materials
                      or websites, or for any other materials, products, or
                      Services of third parties.
                    </li>
                    <li>
                      <strong>7.6.</strong> During the terms of this Agreement,
                      Soul Studio might incorporate products/ Services from
                      third-party providers on SOULSARA. By using such products/
                      Services, it is understood that the user has read,
                      understood, and agreed to the terms and conditions of the
                      corresponding third-party provider.
                    </li>
                    <li>
                      <strong>7.7.</strong> Any complaints, claims, concerns, or
                      questions regarding third-party products should be
                      directed to the third party.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>8. Entire Agreement</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      <strong>8.1.</strong> The failure of Parties to exercise
                      or enforce any right or provision of this Agreement shall
                      not constitute a waiver of such right or provision.
                    </li>
                    <li>
                      <strong>8.2</strong> This Agreement and any policies or
                      operating rules posted by Soul Studio on SOULSARA or with
                      respect to the Services constitute the entire agreement
                      and understanding between the Parties.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>9. Severability</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      In the event that any provision of this Agreement is
                      determined to be unlawful, void or unenforceable, such
                      provision shall nonetheless be enforceable to the fullest
                      extent permitted by applicable law.
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>10. Grievance Redressal</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      To address the grievances of the employees, Soul Studio
                      has set up a Grievance Redressal Forum. In case the
                      employee is dissatisfied with any aspect of our Services,
                      you may contact our Grievance Redressal Officer at
                      <a href="mailto:contact@soulstudio.world">
                        contact@soulstudio.world
                      </a>
                      .
                    </li>
                  </ul>
                </div>
                <div className={staticstyles.row}>
                  <p>
                    <strong>11. Jurisdiction</strong>
                  </p>
                  <ul className={staticstyles.none_style}>
                    <li>
                      Any and all disputes arising between the Company and Soul
                      Studio with regards to this Agreement, including the
                      interpretation of the terms of this User Agreement shall
                      be subject to the exclusive jurisdiction of the courts at
                      Mumbai, India.
                    </li>
                  </ul>
                </div>
                {getUserByID?.is_payment === false &&
                getUserByID.package_name === null ? (
                  <div className="agree_checkbox_wrapper">
                    <Checkbox onChange={onChange}>
                      By tapping “proceed Checkout”
                    </Checkbox>
                    <div className="btn_box">
                      {/* Stripe Payment */}
                      {/* <button
                        className="tapping_btn btn-an4 btn-hover1"
                        disabled={agreeed ? false : true}
                        onClick={() => setIsModalOpen(true)}
                      >
                        Proceed Checkout
                      </button> */}

                      {/* HDFC Payment */}
                      <button
                        className="tapping_btn btn-an4 btn-hover1"
                        disabled={agreeed ? false : true}
                        onClick={() => setIsModalOpen(true)}
                      >
                        Proceed Checkout
                      </button>
                      <button
                        className="tapping_btn empty_btn"
                        onClick={() => setIsModalOpenCompany(true)}
                      >
                        Edit Company Data
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}

      {/* {isLoggedIn ? <Footer1 /> : <Footer1 />} */}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="helpline_payment"
        closable={true}
        maskClosable={false}
        width={"100%"}
      >
        <PaymentHDFC />
        {/* Stripe */}
        {/* <PaymentPopup paymentOption={paymentOption} /> */}
      </Modal>
      <Modal
        open={isModalOpenCompany}
        onOk={handleOkCompany}
        // onCancel={handleCancelCompany}
        footer={null}
        className="helpline_payment"
        closable={false}
        maskClosable={false}
        width={"100%"}
      >
        <AgreementData comapnyId={agreementData?._id} />
      </Modal>

      <Modal
        open={isPaymentStatus}
        onOk={handlePaymentStatus}
        onCancel={handleCancelPaymentStatus}
        footer={null}
        closable={true}
        maskClosable={false}
        width={"390px"}
        className="paymat_status_modal"
      >
        <PaymentStatusPopup />
      </Modal>
      <Modal
        open={isPaymentStatusFail}
        onOk={handlePaymentStatusFail}
        onCancel={handleCancelPaymentStatusFail}
        footer={null}
        closable={true}
        maskClosable={false}
        width={"390px"}
        className="paymat_status_modal"
      >
        <PaymentStatusFail RetryPayment={RetryPayment} />
      </Modal>
    </div>
  );
}

export default SoulsaraAgreement;
