import React, { useEffect, useState } from "react";
import { Button, Typography, message, Image, Form, Select } from "antd";
import { authSVC, categorySVC } from "../../services";
import { useB2BContext } from "../../context/ContextB2B";
import DataTable from "../../components/dataTable/b2b/DataTable";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useSWR from "swr";
import * as Cookie from "universal-cookie";
import { Checkbox, Modal } from "antd";
import { Option } from "antd/es/mentions";

const cookie = new Cookie.default();
const cachedToken = cookie.get("userAuthTokenAdmin");

const { Text } = Typography;

interface Contentdata {
  _id: string;
  full_name: string;
  email: string;
  user_type: string;
  company: string;
  department: string;
}

const fetcherOne = (url: any) =>
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cachedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res?.data?.data);

function Employees() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { getUserByID, setAllPackageList, allPackageList } = useB2BContext();
  const [editContent, setEditContent] = useState<Contentdata>({
    _id: "",
    full_name: "",
    email: "",
    user_type: "",
    company: "",
    department: "",
  });
  const [contentData, setContentData] = useState<any[]>([]);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [disableAddEmp, setDisableAddEmp] = useState(false);
  const [userPackage, setUserPackage] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [isDownloadLoding, setIsDownloadLoding] = useState(false);
  const [csvFile, setCSVFile] = useState<any>(null);
  const navigate = useNavigate();
  const [csvUploadPopup, setCsvUploadPopup] = useState(false);
  const [packageId, setPackageId] = useState<any>();
  const [packageName, setPackageName] = useState<any>();
  const [department, setDepartment] = useState<any>();
  const [packageList, setPackageList] = useState([]);
  const [allDepartment, setAllDepartment] = useState<any>();

  useEffect(() => {
    console.log(allDepartment, "allDepartment");
  }, [allDepartment]);

  const CancelCsvUploadPopup = () => {
    setCsvUploadPopup(false);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "text/csv") {
        setCSVFile(file);
      } else {
        message.error("Please upload a valid CSV file.");
      }
    }
  };

  const UploadCSVFile = async () => {
    const formData: any = new FormData();
    formData.append("package_name", packageName);
    formData.append("department", department);
    formData.append("package_id", packageId);
    formData.append("csvFile", csvFile);
    // package_name
    // department
    // package_id
    console.log(formData, "fff");
    setIsLoding(true);
    await authSVC
      .UploadCompanyCSV(formData)
      .then((res: any) => {
        getEmployees();
        setIsLoding(false);
        setCSVFile(null);
        setPackageName("");
        setDepartment("");
        setPackageId("");
        setCsvUploadPopup(false);
        message.success("Users added successfully from CSV file.");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const getEmployees = async () => {
    if (getUserByID?.email) {
      await authSVC
        .getCompanyuser()
        .then((res: any) => {
          setContentData(res.data?.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    getEmployees();
  }, [getUserByID]);

  const API_PATH = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getDepartments(getUserByID);
  }, [getUserByID]);

  const getDepartments = (getUserByID: any) => {
    if (getUserByID?.company) {
      authSVC
        .getCompanyDepartments(getUserByID?.company)
        .then((res: any) => {
          setAllDepartment(res.data.data);
        })
        .catch((err: any) => {
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    setUserPackage(getUserByID?.package_name);
  }, [getUserByID]);

  const deleteUser = (email: string) => {
    console.log(email);
    authSVC
      .DeleteUser(email)
      .then((response) => {
        message.success("Delete successfully");
        getEmployees();
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  };

  const editCategory = (id: string) => {
    const findByid = contentData.find((item: any) =>
      item._id === id ? item : null
    );

    setEditContent({
      _id: findByid?._id,
      full_name: findByid?.full_name ?? findByid?.first_name,
      email: findByid?.email,
      user_type: findByid?.user_type,
      company: findByid?.company,
      department: findByid?.department,
    });
  };

  const filteredContents = contentData?.filter((item: any) => {
    return item?.email === getUserByID?.email ? null : item;
  });

  useEffect(() => {
    if (filteredContents?.length === 10 && userPackage === "Gold") {
      setDisableAddBtn(true);
    } else if (filteredContents?.length === 100 && userPackage === "Diamond") {
      setDisableAddBtn(true);
    } else if (filteredContents?.length >= 100 && userPackage === "Platinum") {
      setDisableAddBtn(true);
    } else {
      setDisableAddBtn(false);
    }
  }, [filteredContents, userPackage]);

  const downloadFile = () => {
    const headers = ["Name", "Email", "Department"];
    const rows =
      contentData &&
      contentData?.map((row: any) => [
        row.full_name,
        row.email,
        row.department,
      ]);

    // Combine headers and rows into a single array
    const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Set the URL using createObjectURL
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Employee_Data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleChange = (value: string) => {
    setDepartment(value);
  };

  const handleSelectedPackage = (packageValue: any) => {
    console.log(`Value for package ${JSON.parse(packageValue)}`);
    const value = JSON.parse(packageValue);
    if (value) {
      console.log(`Setting ${value._id} and ${value.package}`);
      setPackageId(value._id);
      setPackageName(value.package);
    }
  };

  useEffect(() => {
    console.log(`Packagessss list`, getUserByID?.company_id?.package);
    setPackageList(getUserByID?.company_id?.package);
  }, [getUserByID]);

  const handleDownload = () => {
    const csvData = [
      ["Full Name", "Email Id", "Occupation", "Marital Status", "Gender"],
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Step 2: Create Blob
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Step 3: Generate URL
    const url = URL.createObjectURL(blob);

    // Step 4: Open in New Tab or Trigger Download
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open in new tab
    link.download = "EmployeeFormatExample.csv"; // Suggest a file name
    link.click();

    // Clean up URL object
    URL.revokeObjectURL(url);
    // const filePath = "/files/EmployeeFormatExample.csv";
    // const link = document.createElement("a");
    // link.href = filePath;
    // link.download = "EmployeeFormatExample.csv";
    // link.click();
    // // window.location.href = "/EmployeeFormatExample.csv";
  };

  return (
    <div className="contentBox">
      <div className="info">
        {getUserByID ? (
          allDepartment?.length === 0 ? (
            <button
              onClick={() => navigate("/adddepartments")}
              className="cn_btn"
              style={{ width: "200px" }}
            >
              Add Department
            </button>
          ) : (
            <div className="employ_btn_set">
              <Button
                onClick={() => navigate("/addemployees")}
                className="cn_btn"
                style={{ width: "max-content" }}
                disabled={
                  getUserByID?.is_payment === false
                    ? true
                    : disableAddBtn
                    ? true
                    : false
                }
              >
                Add New Employee
              </Button>
              <div className="csv_upload">
                <Button
                  className="cn_btn"
                  style={{ width: "max-content" }}
                  onClick={() => setCsvUploadPopup(true)}
                >
                  Bulk Users Upload
                </Button>
                <Button
                  disabled={
                    getUserByID?.is_payment === false ? true : isDownloadLoding
                  }
                  className="cn_btn"
                  style={{ width: "max-content" }}
                  onClick={downloadFile}
                >
                  Export Users Data
                </Button>
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      <DataTable
        email={getUserByID?.email}
        deleteuser={deleteUser}
        editContent={editCategory}
        setshowModel={setIsModalOpen}
        setEditable={setIsEditable}
        isOpen={isModalOpen}
        contentData={filteredContents}
      />
      <Modal
        open={csvUploadPopup}
        onCancel={CancelCsvUploadPopup}
        footer={null}
        closable={true}
        maskClosable={false}
        width={"390px"}
        // className="paymat_status_modal"
      >
        <div className="csv_upload_wrapper">
          <div className="item">
            <label htmlFor="">Package Name</label>
            {allPackageList && (
              <Form.Item
                name="package_name"
                rules={[
                  { required: true, message: "Please input Package Name!" },
                ]}
                className="form_item_blue"
              >
                <Select
                  style={{ width: "100%", borderRadius: "24px" }}
                  placeholder="Select employee package"
                  optionFilterProp="children"
                  onChange={handleSelectedPackage}
                >
                  {packageList?.map((packageDetail: any) => (
                    <option value={JSON.stringify(packageDetail.package_id)}>
                      {packageDetail.package_id.package}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
          <div className="item">
            <label htmlFor="">Department Name</label>
            <Form.Item style={{ marginBottom: "16px" }} name={"department"}>
              <Select
                style={{ width: "100%", borderRadius: "24px" }}
                placeholder="Select employee department"
                optionFilterProp="children"
                onChange={handleChange}
              >
                {allDepartment?.map((item: any, index: any) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="item">
            <div className="csv_upload">
              {getUserByID?.is_payment === false ? null : (
                <div className="upload_btn" style={{ width: "100%" }}>
                  <div className="choosefile">
                    <input
                      className="file"
                      title="Choose CSV File"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      style={{ width: "100%" }}
                    />
                    {csvFile ? (
                      csvFile?.name
                    ) : (
                      <>
                        <Image
                          src="/img/new/csv_icon.svg"
                          alt="import"
                          preview={false}
                          height={22}
                        />
                        Choose File
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="item">
            <Button
              style={{
                width: "max-content",
                border: "none",
                padding: "0",
                color: "#2E3191",
                margin: "20px 0 0 0",
                fontWeight: "bold",
              }}
              onClick={handleDownload}
            >
              Download Sample Format
            </Button>
            {/* <a
              href="/files/EmployeeFormatExample.csv"
              download="EmployeeFormatExample.csv"
              style={{
                width: "max-content",
                border: "none",
                padding: "0",
                color: "#2E3191",
                margin: "20px 0 0 0",
                fontWeight: "bold",
              }}
            >
              Download Sample Format
            </a> */}
          </div>
          <div className="item">
            <Button
              disabled={csvFile && department && packageName ? false : true}
              className="cn_btn"
              onClick={UploadCSVFile}
            >
              {isLoding ? "Processing..." : "Import CSV"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Employees;
