import { AxiosPromise } from 'axios';

const API_PATH = process.env.REACT_APP_API_URL;
const WELLNESS_API_PATH = process.env.REACT_APP_WELLNESS_API;

type API = {
  post: (url: string, payload: any) => AxiosPromise
  put: (url: string, payload: any) => AxiosPromise
  get: (url: string) => AxiosPromise
  delete: (id: string) => AxiosPromise
}

const auth = (api: API, formData: API, formDataApi: API) => {
  // auth
  const login = (payload: any) => api.post(API_PATH + '/api/login/', payload)
  // const register = (payload:any) => api.post(API_PATH + '/api/register/', payload)
  // const tokenVerify = (payload:any) => api.post(API_PATH + `/api/verify/`, payload)
  const adduser = (payload: any) => api.post(API_PATH + '/admin/addUser/', payload)
  const updateUser = (userEmail: any, payload: any) => api.put(API_PATH + `/admin/updateUser/${userEmail}`, payload)
  const updateEmployee = (id: any, payload: any) => api.put(API_PATH + `/employee/update/${id}`, payload)
  const addHruser = ( payload: any) => api.post(API_PATH + '/admin/addAdmin/', payload)
  const getCompanyuser = () => api.get(API_PATH + '/employee')
  const UploadCompanyCSV = (payload: any) => formDataApi.post(API_PATH + '/admin/addUsersFromCSV', payload)
  const UpdatePackegeData = (payload: any, id: any) => api.put(API_PATH + `/admin/updatePackage/${id}`, payload)
  const DeleteUser = (email: any) => api.delete(API_PATH + `/admin/deleteUser/${email}`)
  const getAllBookedSession = () => api.get(API_PATH + `/session/bookedSession`)
  const getAllCount = () => api.get(API_PATH + `/session/bookedSessionCount`)

  const getByUID = (id: any) => api.get(API_PATH + `/api/users/${id}`);
  const getByUEmail = (id: any) => api.get(API_PATH + `/api/users/email/${id}`);
  const updateByUID = (id: any, payload: any) => formData.put(API_PATH + `/api/users/${id}`, payload)
  const changePassword = (payload: any) => api.post(API_PATH + '/api/change-password', payload)
  const forgotPassword = (payload: any) => api.post(API_PATH + '/api/forgot-password', payload)
  const paymentDetails = (id: any) => api.get(API_PATH + `/membership/getByUserId/${id}`)
  const DemoUsers = () => api.get(API_PATH + `/businessLead/`)
  const GetAllAdmin = () => api.get(API_PATH + `/admin/getAllAdmin/`)
  const getAuditReport = (id: any) => api.get(WELLNESS_API_PATH + `/hrreport/${id}`);
  const getOverallWellness = (company: string, department?: string) => api.get(WELLNESS_API_PATH + `/liveInsight/report?department=${department}&company=${company}`)
  const getOverallWellnesspieChartLevels = (company:string, month:number) => api.post(WELLNESS_API_PATH + `/liveInsight/barChartLevels`, {
    "company": company,
    "month": month
  });
  const getOverallHappinesspieChartLevels = (company:string, month:number) => api.post(WELLNESS_API_PATH + `/liveInsight/pieChartLevelsHappiness`, {
    "company": company,
    "month": month
  });
  
  const getbarChartWellnessLevels = (company:string, year:number) => api.post(WELLNESS_API_PATH + `/liveInsight/departmentBarChartOverview`, {
    "company": company,
    "year": year
  });
  const getbarChartHappinessLevels = (company:string, year:number) => api.post(WELLNESS_API_PATH + `/liveInsight/departmentBarChartHappinessOverview`, {
    "company": company,
    "year": year
  });

  const getDepartmentLevels = (company:string, month:number, year?:number) => api.post(WELLNESS_API_PATH + `/liveInsight/departmentOverview`, {
    "company": company,
    "month": month,
    "year": year
  });
  const getDepartmentHappinessLevels = (company:string, month:number, year?:number) => api.post(WELLNESS_API_PATH + `/liveInsight/departmentOverviewHappiness`, {
    "company": company,
    "month": month,
    "year": year
  });
  const getoverAllViewLevels = (company:string, month:number, year?:number) => api.post(WELLNESS_API_PATH + `/liveInsight/overAllViewLevels`, {
    "company": company,
    "month": month,
    "year": year
  });
  const removeDepartment = (companyName:string, departmentNames:any) => api.put(API_PATH + `/admin/removeDepartments`, {
    "companyName": companyName,
    "departmentNames": departmentNames,
  });
  const CreateSession = (payload: any) => api.post(API_PATH + '/session/create', payload);
  const GetAllSessionV1 = (date: any) => api.get(API_PATH + `/session/admin/${date}`);
  const addCompanyDepartments = (payload: any) => api.post(API_PATH + `/admin/addCompanyDepartments`, payload);
  const getCompanyDepartments = (company: any) => api.get(API_PATH + `/admin/${company}/departments`);
  const getAnonymousReport = (company: any, department: any) => api.get(API_PATH + `/admin/${company}/${department}/users`);
  const editDepartment = (payload: any) => api.put(API_PATH + `/api/update/department`, payload)


  const getAllRioData = () => api.get(API_PATH + `/roi/all`)
  const getAllOcaData = () => api.get(API_PATH + `/oca/all`)
  const getAllWellnessData = () => api.get(`https://8pg4jnme8f.us-east-1.awsapprunner.com/audit/getALL`)
  const getCodeDemoUrl = () => api.get(API_PATH + `/activation/getCode`);

  const MembershipUpdates = (payload: any) => api.post(API_PATH + `/requestUpgrade/create`, payload);
  const getMembershipUpdates = (id: any) => api.get(API_PATH + `/requestUpgrade/get/${id}`)

  const getAllModule = () => api.get(API_PATH + `/modules/getAllModules`)
  const getAllPackage = () => api.get(API_PATH + `/modules/getAllPackage`)
  
  const postMapModules = (payload: any) => api.post(API_PATH + `/modules/mapModules`, payload)

  const agreementsPost = (payload: any) => api.post(API_PATH + `/agreements`, payload);
  const getAgreementsPostData = (id: any) => api.get(API_PATH + `/agreements/byUserEmail/${id}`);
  const AgreementsDone = (id: any, payload: any) => api.put(API_PATH + `/agreements/byUserEmail/${id}`, payload);
  const ProssedPayment = (payload: any) => api.post(API_PATH + `/payments/addPayment`, payload)
  const Payment = (payload: any) => api.post(API_PATH + `/payment/addPayment`, payload);
  const approvePayment = (payload: any) => api.post(API_PATH + `/admin/approvePayment`, payload);
  const GetModules = (companyId: any, packageId: any) => api.get(API_PATH + `/modules/getCompanyModule/${companyId}/${packageId}`)

  const initateHRMSPayment = (payload: any) => api.post(API_PATH + `/hdfcpayment/initateHRMSPayment`, payload);
  const checkPaymentStatus = (payload: any) => api.post(API_PATH + `/hdfcpayment/checkPaymentStatus`, payload);
  const closeHdfcHRTransaction = (payload: any) => api.post(API_PATH + `/hdfcpayment/closeHdfcHRTransaction`, payload);
  
  const CompanyAddress = (payload: any) => api.post(API_PATH + `/admin/updateCompanyAddress `, payload);
  const setFirstWelcomeMessage = (payload: any) => api.post(API_PATH + `/api/setFirstWelcomeMessage `, payload);

  const getMappedPackageList = (id: any) => api.get(API_PATH + `/modules/getMappedPackageList/${id}`);

  

  
  
  


 

  
  
  

  

  return {
    getAllCount,
    getAllBookedSession,
    login,
    adduser,
    getCompanyuser,
    getByUID,
    updateByUID,
    changePassword,
    paymentDetails,
    DemoUsers,
    GetAllAdmin,
    UpdatePackegeData,
    getByUEmail,
    getAuditReport,
    CreateSession,
    GetAllSessionV1,
    addHruser,
    UploadCompanyCSV,
    addCompanyDepartments,
    getCompanyDepartments,
    getAnonymousReport,
    DeleteUser,
    getOverallWellness,
    getOverallWellnesspieChartLevels,
    getDepartmentLevels,
    getoverAllViewLevels,
    getbarChartWellnessLevels,
    getbarChartHappinessLevels,
    getDepartmentHappinessLevels,
    getOverallHappinesspieChartLevels,
    removeDepartment,
    getAllRioData,
    getAllOcaData,
    forgotPassword,
    getCodeDemoUrl,
    updateUser,
    editDepartment,
    getAllWellnessData,
    MembershipUpdates,
    getMembershipUpdates,
    getAllModule,
    postMapModules,
    getAllPackage,
    agreementsPost,
    getAgreementsPostData,
    AgreementsDone,
    ProssedPayment,
    Payment,
    approvePayment,
    GetModules,
    initateHRMSPayment,
    checkPaymentStatus,
    closeHdfcHRTransaction,
    CompanyAddress,
    setFirstWelcomeMessage,
    updateEmployee,
    getMappedPackageList
  }
}

export default auth
